.about-clix-bg {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    background-image: url(../../../Assets/img/about-bg.png);
}

.about-clix-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 15, 22, 0.8);
}

.about-top-title {
    position: relative;
}

.about-top-title::before {
    content: "";
    position: absolute;
    left: 156px;
    top: 100%;
    width: 80px;
    height: 0px;
    border: 1px solid white;
}