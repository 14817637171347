.collection-Bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.collection-Bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 15, 22, 0.8);
}

.collection-button {
    color: #079E01;
    padding: 8px 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    line-height: 24px;
    font-size: 16px;
    border-radius: 50px;
}

.collection-button:hover {
    color: #FFF;
}

.collection-button::before {
    content: "";
    border: 2px solid #079E01;
    border-radius: 50px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
}

.collection-button::after {
    content: "";
    border-radius: 50px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0;
}

.collection-button:hover::after {
    background-color: #079E01;
    height: 100%;
    transition: all 0.3s ease-out;
    transform: scale(1.1);
    transition-delay: 0.5ms;
}

/* New styles */
.card {
    text-align: center;
    padding: 10px;
}

.card .colltect_title {
    font-size: 20px; /* Adjust the font size here */
    font-weight: bold;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

figure {
    margin: 0;
}