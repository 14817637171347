.bannerBg {
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
}

.text-container {
    background: rgba(0, 0, 0, 0.6);
    padding: 1rem;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .text-container {
        background: rgba(0, 0, 0, 0.8);
    }
}

@media (max-width: 640px) {
    .bannerBg {
        background-position: center;
    }
}

@media (max-width: 1024px) {
    .bannerBg {
        background-position: center;
    }
}


.banner-border {
    position: relative;
    width: 1px;
    height: 700px;
    border: 1px solid white;
}

.banner-shape1 {
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 50px;
}

.banner-shape2 {
    width: 30px;
    height: 30px;
    border: 2px solid white;
    border-radius: 50px;
}

.banner-button {
    color: #FFF;
    padding: 8px 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    line-height: 24px;
    font-size: 16px;
    border-radius: 50px;
}

.banner-button:hover {
    color: #FFF;
}

.banner-button::before {
    content: "";
    border: 2px solid #079E01;
    border-radius: 50px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
}

.banner-button::after {
    content: "";
    border-radius: 50px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0;
}

.banner-button:hover::after {
    background-color: #079E01;
    height: 100%;
    transition: all 0.3s ease-out;
    transform: scale(1.1);
    transition-delay: 0.5ms;
}