.corebg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../Assets/img/corebg.png);
    position: relative;
}

.corebg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #140F16;
    opacity: 80%;
}

.tram-to-title {
    position: relative;
}

.tram-to-title::before {
    content: "";
    position: absolute;
    left: 65px;
    top: 100%;
    width: 80px;
    height: 0px;
    border: 1px solid white;
}