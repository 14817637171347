.card-area {
    background-image: url('../../../Assets/img/core-border-img.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    position: relative;
}

.btn-core {
    border: 2px solid #079E01;
    border-radius: 5px;
    color: white;
}