/* Note: Don't use slick-theme.css file */

/* Adding margin between slides */
.slick-list {
    margin: 0 0px;
}

.slick-slide > div {
    margin: 0 10px;
}

.teamCard {
    display: flex;
    max-width: 100%;
    background-color: #222;
    border-radius: 10px;
    padding: 20px;
    text-align: left;
    color: white;
    height: 300px; /* Add a fixed height to the teamCard */
}

.teamImage {
    width: 40%;
    object-fit: cover;
    border-radius: 50%;
    height: auto;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px;
}

.social-media {
    display: flex;
    gap: 10px;
}

.social-link img {
    width: 90px;
    height: 24px;
    transition: transform 0.3s;
}

.social-link:hover img {
    transform: translateY(-3px);
}

.card-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 5px;
}

.card-title span {
    color: #079E01;
}

.card-subtitle {
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 10px;
}

.social-link:hover {
    opacity: 0.8;
    transition: opacity 0.3s;
}

.card-title {
    font-size: 15px; /* Adjust the font size */
    line-height: 2px; /* Adjust the line height */
}

.teamAbout {
    font-size: 12px; /* Adjust the font size */
}

.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -12%;
    display: block;
    width: 24px;
    height: 44.47px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    z-index: 1;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
    opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev:before,
.slick-next:before {
    display: none;
}

.slick-prev:before,
.slick-next:before {
    line-height: 1;
    opacity: .75;
    /* color: #333333; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card-body.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-title,
h5 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.teamCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1; /* Add this line to make the content take the remaining space */
}

@media (min-width: 720px) {
    .teamCard {
        flex-direction: row;
    }
}

[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}

.slick-next {
    right: 105px;
}

[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}

@media (min-width: 320px) {
    .slick-prev {
        left: 46%;
        top: 6%;
    }

    .slick-next {
        top: 6%;
        right: 66px;
    }
}

@media (min-width: 360px) {
    .slick-prev {
        left: 50%;
        top: 6%;
    }

    .slick-next {
        top: 6%;
        right: 66px;
    }
}

@media (min-width: 375px) {
    .slick-prev {
        left: 52%;
        top: 6%;
    }

    .slick-next {
        top: 6%;
        right: 66px;
    }
}

@media (min-width: 640px) {
    .slick-prev {
        left: 71%;
    }

    .slick-next {
        right: 65px;
    }
}

@media (min-width: 658px) {
    .slick-prev {
        left: 65%;
    }

    .slick-next {
        right: 105px;
    }
}

@media (min-width: 712px) {
    .slick-prev {
        left: 82%;
        top: 4%;
    }

    .slick-next {
        top: -8%;
        right: 105px;
    }
}

@media (min-width: 768px) {
    .slick-prev {
        left: 82%;
        top: 2%;
    }

    .slick-next {
        top: -12%;
        right: 105px;
    }
}

@media (min-width: 1024px) {
    .slick-prev {
        left: 72%;
        top: -12%;
    }

    .slick-next {
        top: -12%;
        right: 105px;
    }
}

@media (min-width: 1280px) {
    .slick-prev {
        left: 77%;
    }
}

@media (min-width: 1366px) {
    .slick-prev {
        left: 74%;
    }
}

@media (min-width: 1536px) {
    .slick-prev {
        left: 77%;
    }

    .slick-next {
        top: -12%;
        right: 105px;
    }
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    transition: .3s ease all;
}

.slick-dots li * {
    transition: .3s ease all;
}

.slick-dots li.slick-active button {
    opacity: 100;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-self: center;
    width: 32px;
    height: 32px;
    padding: 0px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    border: 2px solid #fff;
    border-radius: 50px;
    position: relative;
    justify-content: center;
    align-items: center;
}

.slick-dots li button:before {
    opacity: 0;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
}

.slick-dots li.slick-active button {
    border: 2px solid #079E01;
    opacity: 1;
    transform: scale(.9);
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
    color: #079E01;
}

.slick-dots li button:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #079E01;
    border-radius: 50px;
    display: flex;
    position: unset;
    display: block;
    flex-shrink: 0;
}

.text-area {
    position: relative;
}

.text-area::before {
    content: "";
    position: absolute;
    left: 90px;
    top: 100%;
    width: 80px;
    height: 0px;
    border: 1px solid white;
}

.card.shadow-xl.sliderItem .card-body {
    padding: 0;
}

.slick-initialized .slick-slide {
    transition: .3s ease all;
}