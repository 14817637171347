.menu :where(li:not(.menu-title):not(:empty))> :where(:not(ul).active),
.menu :where(li:not(.menu-title):not(:empty))> :where(*:not(ul):active) {
    --tw-bg-opacity: 1;
    background-color: transparent;
    --tw-text-opacity: 1;
    color: #FFF;
}

.menu :where(li:not(.menu-title):not(:empty))> :where(*:not(ul):focus),
.menu :where(li:not(.menu-title):not(:empty))> :where(*:not(ul):hover) {
    background-color: hsl(var(--bc) / var(--tw-bg-opacity));
    --tw-bg-opacity: 0.1;
    color: #FFF;
}

.hero-navbar-area {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    width: 100%;
}

.hero-navbar {
    background-color: #202020;
    box-shadow: 0px 2px 6px 0px #eb8e4b;
}

.navbar-menu li a {
    line-height: 23px;
    color: rgba(204, 204, 204, 0.7);
    padding: 10px 20px;
}

.navbar-menu li:last-child a {
    padding-right: 0px;
}

.open-nav {
    color: #FFF;
    font-size: 40px;
    transition-duration: 0.5s;
    line-height: 0;
    cursor: pointer;
}

.close-nav {
    color: #FFF;
    font-size: 40px;
    line-height: 0;
    cursor: pointer;
}

@media (max-width:1024px) {
    .activeOpen {
        left: 0px;
        top: 73px;
    }

    .menu-area {
        width: 500px;
        min-height: 80vh;
    }

    .menu-area ul {
        width: 100%;
        text-align: center;
    }

    .menu-area ul li a {
        color: #079E01;
        padding: 10px 20px;
        display: inline-block;
        font-weight: 300;
        font-family: 'Inter' sans-serif;
    }
}

@media (max-width:640px) {
    .activeOpen {
        left: 0px;
        top: 73px;
    }

    .menu-area {
        width: 330px;
        min-height: 80vh;
    }

    .menu-area ul {
        width: 100%;
        text-align: center;
    }

    .menu-area ul li a {
        color: #079E01;
        padding: 10px 20px;
        display: inline-block;
        font-weight: 300;
        font-family: 'Inter' sans-serif;
    }
}

@media (max-width:360px) {
    .activeOpen {
        left: 0px;
        top: 72px;
    }

    .menu-area {
        width: 330px;
        min-height: 80vh;
    }

    .menu-area ul {
        width: 100%;
        text-align: center;
    }

    .menu-area ul li a {
        color: #079E01;
        padding: 10px 20px;
        display: inline-block;
        font-weight: 300;
        font-family: 'Inter' sans-serif;
    }
}


.btn-button {
    color: #079E01;
    padding: 8px 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    line-height: 24px;
    font-size: 16px;
}

.btn-button:hover {
    color: #FFF;
}

.btn-button::before {
    content: "";
    border: 2px solid #079E01;
    border-radius: 5px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0;
}

.btn-button::after {
    content: "";
    border-radius: 20px;
    position: absolute;
    z-index: -1;
    display: block;
    width: 100%;
    height: 0%;
    left: 0px;
    top: 0;
}

.btn-button:hover::after {
    background-color: #079E01;
    height: 100%;
    transition: all 0.3s ease-out;
    transform: scale(1.1);
    transition-delay: 0.5ms;
}