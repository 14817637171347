.flow-chart-bg {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.flow-chart-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 15, 22, 0.8);
}